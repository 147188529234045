// $rtl: ".rtl :host &";
$rtl: "[dir=" rtl "] &";

:root {
  --primary-color: #9e1e22;
  --primary-color-rgb: 158, 30, 34;

  --dynamic-color: #9e1e22; // used in survey design component to change color dynamically
}
$primary-official: #9e1e22;
$primary: var(--primary-color); // primary
$primary-10: rgba(var(--primary-color-rgb), 0.1);
$primary-hover: rgba(var(--primary-color-rgb), 0.9);
$black: #4d4d4d;
$dark-gray: #8d8d8d;
$white: #fafafa;
$gray-placeholders: #c6c6c6;
$green: #4f9e1e;
$warning: #d0292e;
$link: #65a5c7;
$dark-accent: #d9584f;
$light-accent: #e09990;
$real-white: #fff;
$purple: #631e9e;

$dynamicColor: var(--dynamic-color);
$lottie-color: var(--lottie-color);

// breakpoint media query
$phone: "screen and (max-width: 599.98px)";
$tablet: "screen and (min-width: 599.98px) and (max-width: 959.98px)";
$desktop: "screen and (min-width: 959.98px) and (max-width: 1279.98px)";

$lg: "screen and (min-width: 1280px )"; // = gt-lg // lg = min: 1280, max: 1919.98
$md: "screen and (min-width: 959.98px) and (max-width:1279.98px)"; // = lt.md // md = min: 960, max: 1279.98
$sm: "screen and (min-width: 599.98px) and (max-width:959.98px)"; // = lt-sm // sm = min: 600, max: 959.98
$xs: "screen and (max-width: 599.98px)";

@mixin Arabic-Family() {
  font-family: "Baloo Bhaijaan 2";
}

// extendable class with ( size - height - family )
@mixin Font-Bold($arabic-font: false) {
  line-height: normal;
  font-weight: bold;
  letter-spacing: 0.2px;
  @if $arabic-font {
    @include Arabic-Family();
  } @else {
    font-family: Quicksand;
    &.ar {
      @include Arabic-Family();
    }
  }
}

@mixin Font-SemiBold($arabic-font: false) {
  line-height: normal;
  font-weight: 600;
  letter-spacing: 0.2px;
  @if $arabic-font {
    @include Arabic-Family();
  } @else {
    font-family: Quicksand;
    &.ar {
      @include Arabic-Family();
    }
  }
}

@mixin Font-Medium($arabic-font: false) {
  line-height: normal;
  font-weight: 500;
  letter-spacing: 0.2px;
  @if $arabic-font {
    @include Arabic-Family();
  } @else {
    font-family: Quicksand;
    &.ar {
      @include Arabic-Family();
    }
  }
}

@mixin Font-Regular($arabic-font: false) {
  line-height: normal;
  font-weight: normal;
  letter-spacing: 0.2px;
  @if $arabic-font {
    @include Arabic-Family();
  } @else {
    font-family: Quicksand;
    &.ar {
      @include Arabic-Family();
    }
  }
}

@mixin Font-Light($arabic-font: false) {
  line-height: normal;
  font-weight: 300;
  letter-spacing: 0.2px;
  @if $arabic-font {
    @include Arabic-Family();
  } @else {
    font-family: Quicksand;
    &.ar {
      @include Arabic-Family();
    }
  }
}

%Font-Bold {
  @include Font-Bold;
}
%Font-SemiBold {
  @include Font-SemiBold;
}
%Font-Medium {
  @include Font-Medium;
}
%Font-Regular {
  @include Font-Regular;
}
%Font-Light {
  @include Font-Light;
}

// shared input style // will update this
%input-default {
  color: $black;
  @extend %Font-Medium;
  font-size: 14px;
  width: 300px;
  height: 42px;
  padding: 11px 16px;
  border-radius: 4px;
  border: solid 1px rgba($black, 0.1);
  background-color: $white;
}
