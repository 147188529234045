// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "~@angular/material" as mat;

@import "./constants.scss";

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
$button-line-height: 42px;

$custom-typography: mat.define-typography-config(
  $font-family: "Quicksand, sans-serif;",
  $button: mat.define-typography-level(14px, $button-line-height, 600),
);

$arabic-typography: mat.define-typography-config(
  $font-family: "'Baloo Bhaijaan 2', sans-serif;",
  $button: mat.define-typography-level(14px, $button-line-height, 600),
);
// $display-4: define-typography-level($font-size: 112px, $line-height: 112px, $font-weight: 300, $letter-spacing: -0.05em),
// $display-3: define-typography-level($font-size: 56px, $line-height: 56px, $font-weight: 400, $letter-spacing: -0.02em),
// $display-2: define-typography-level($font-size: 45px, $line-height: 48px, $font-weight: 400, $letter-spacing: -0.005em),
// $display-1: define-typography-level($font-size: 34px, $line-height: 40px, $font-weight: 400),
// $headline:  define-typography-level($font-size: 24px, $line-height: 32px, $font-weight: 400),
// $title:          define-typography-level($font-size: 20px, $line-height: 32px, $font-weight: 500),
// $subheading-2:  define-typography-level($font-size: 16px, $line-height: 28px, $font-weight: 400),
// $subheading-1:  define-typography-level($font-size: 15px, $line-height: 24px, $font-weight: 400),
// $body-2:    define-typography-level($font-size: 14px, $line-height: 24px, $font-weight: 500),
// $body-1:    define-typography-level($font-size: 14px, $line-height: 20px, $font-weight: 400),
// $caption:    define-typography-level($font-size: 12px, $line-height: 20px, $font-weight: 400),
// $button:     define-typography-level($font-size: 14px, $line-height: 14px, $font-weight: 500),
// $input:        define-typography-level($font-size: inherit, $line-height: 1.125, $font-weight: 400)
.mat-typography button,
.mat-typography a {
  &.mat-button-disabled {
    cursor: not-allowed;
  }
  &.mat-flat-button,
  &.mat-raised-button {
    line-height: $button-line-height;
    min-width: 100px;
    transition: background 3s ease, opacity 3s ease;
    // transition: line-height 0.5s ease-in-out, background-color 0.3s ease-in-out;
    &.mat-flat-button.mat-button-disabled,
    &.mat-raised-button.mat-button-disabled {
      color: $white;
    }
    // &:not(.social .cdk-focused).cdk-focused {
    //   // line-height: ceil($button-line-height * 0.96);
    //   &.mat-primary {
    //     background-color: $black;
    //   }
    // }
  }
  &.mat-flat-button {
    &:not(.social .mat-primary).mat-primary:not(.mat-button-disabled):hover {
      background-color: $primary-hover;
    }
    &:not(.social .mat-accent).mat-accent:not(.mat-button-disabled):hover {
      background-color: rgba(var(--primary-color-rgb), 0.09);
    }
    &.mat-button-disabled {
      background-color: rgba(var(--primary-color-rgb), 0.5);
    }
  }
  &.mat-raised-button.mat-button-disabled {
    background-color: $gray-placeholders;
  }
}

@include mat.core($custom-typography);
.ar {
  @include mat.core($arabic-typography);
}

$primary-color: (
  500: $primary,
  contrast: (
    500: $white,
  ),
);
$accent-color: (
  500: $primary_10,
  contrast: (
    500: $primary,
  ),
);
$warn-color: (
  500: $warning,
  contrast: (
    500: $white,
  ),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$talent-hub-web-primary: mat.define-palette(
  $primary-color,
  500
); // $pinkish-grey
$talent-hub-web-accent: mat.define-palette($accent-color, 500);

// The warn palette is optional (defaults to red).
$talent-hub-web-warn: mat.define-palette($warn-color, 500);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$talent-hub-web-theme: mat.define-light-theme(
  (
    color: (
      primary: $talent-hub-web-primary,
      accent: $talent-hub-web-accent,
      warn: $talent-hub-web-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($talent-hub-web-theme);

.social {
  $social-color: (
    500: $white,
    contrast: (
      500: $black,
    ),
  );

  $social-primary: mat.define-palette($social-color, 500);
  $social-theme: mat.define-light-theme(
    (
      color: (
        primary: $social-primary,
        accent: $talent-hub-web-accent,
      ),
    )
  );

  @include mat.button-theme($social-theme);
}
