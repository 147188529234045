@import "../constants.scss";

$-checkbox-size: 16px;
$-disabled-color: $gray_placeholders;
$-border-radius: 0;

@mixin configure(
  $checkbox-size: null,
  $disabled-color: null,
  $border-radius: null
) {
  @if $checkbox-size {
    $-checkbox-size: $checkbox-size !global;
  }
  @if $disabled-color {
    $-disabled-color: $disabled-color !global;
  }
  @if $border-radius {
    $-border-radius: $border-radius !global;
  }
}

@mixin styles {
  mat-checkbox {
    @extend %Font-Regular;
    font-size: 12px;
    color: $black;
    .mat-checkbox-inner-container {
      width: $-checkbox-size;
      height: $-checkbox-size;
    }
    &.mat-checkbox-disabled:not(.mat-checkbox-checked) {
      .mat-checkbox-label {
        color: $-disabled-color;
      }
      .mat-checkbox-background {
        background-color: $-disabled-color;
      }
    }
    .mat-checkbox-frame,
    .mat-checkbox-background {
      border-radius: $-border-radius;
      border: solid 1px rgba($black, 0.1);
      background-color: $white;
    }
  }
}
