@import "../constants.scss";

$-width: 14;
$-radio-size: $-width * 1px;
$-disabled-color: $gray_placeholders;

@mixin configure($width: null, $radio-size: null, $disabled-color: null) {
  @if $width {
    $-width: $width !global;
  }
  @if $radio-size {
    $-radio-size: $radio-size !global;
  }
  @if $disabled-color {
    $-disabled-color: $disabled-color !global;
  }
}

@mixin styles {
  mat-radio-group {
    @extend %Font-Regular;
    font-size: 12px;
    color: $black;
    &.hide-radio {
      .mat-radio-container,
      .mat-radio-outer-circle,
      .mat-radio-inner-circle {
        display: none;
      }
    }
    .mat-radio-container,
    .mat-radio-outer-circle,
    .mat-radio-inner-circle {
      width: $-radio-size;
      height: $-radio-size;
    }
    .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
      // border-color: rgba($-color);
      border-width: 4px;
    }
    .mat-radio-button.mat-primary .mat-radio-inner-circle {
      background: transparent;
    }
    .mat-radio-button .mat-radio-ripple {
      left: calc(50% - #{$-radio-size});
      top: calc(50% - #{$-radio-size});
      height: $-width * 2px;
      width: $-width * 2px;
    }
    .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element {
      // background-color: rgba($-color, 0.4) !important;
    }
    .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
      color: $black;
    }
  }
}
