@import "../constants.scss";

@mixin styles {
  .mat-accordion {
    .mat-expansion-panel-header-description {
      justify-content: end;
      align-items: flex-end;
    }

    .mat-expansion-panel {
      border-radius: 8px !important;
      margin-bottom: 16px;
      border: solid 1px rgba($black, 0.1);
      box-shadow: none !important;
    }

    .mat-expansion-panel-header.mat-expanded {
      height: 48px;
    }

    .mat-expansion-panel-body {
      padding: 0 24px 24px;
    }
  }
}
