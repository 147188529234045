@import "../constants.scss";

.input-wrapper {
  position: relative;
  min-width: 100px; // 300px;
  overflow: hidden;
  &.with-prefix input,
  &.with-prefix textarea {
    padding-left: 40px;
    box-sizing: border-box;
    #{$rtl} {
      padding-left: 0;
      padding-right: 40px;
    }
  }
  &.with-suffix input,
  &.with-suffix textarea {
    padding-right: 40px;
    #{$rtl} {
      padding-right: 0;
      padding-left: 40px;
    }
  }
  .icon {
    font-size: 16px;
    height: 16px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    color: $gray_placeholders;
    opacity: 1;
    // transition: opacity 0.3s ease-in-out;
    &.prefix {
      padding-left: 18px;
      #{$rtl} {
        padding-left: 0;
        padding-right: 18px;
      }
    }
    &.suffix {
      margin-right: 14px;
      right: 0;
      border: none;
      background: transparent;
      cursor: pointer;
    }
    &:not(.disabled) {
      opacity: 0.5;
    }
    &.has-value {
      opacity: 1;
      color: $black;
    }
  }
  &:hover .icon:not(.disabled),
  &:hover ::placeholder {
    opacity: 1;
    color: $black;
  }
}

input,
textarea,
mat-select {
  @extend %input-default;
  margin: 0;
  width: 100%;
  // transition: opacity 0.3s ease-in-out;
  &[disabled] {
    border: solid 1px rgba($black, 0.1);
    background-color: #e5e5e5;
    color: $gray_placeholders;
    opacity: 1;
  }
  &:focus {
    outline: auto;
  }
}

// shared classes
input:not([disabled])::placeholder,
textarea:not([disabled])::placeholder {
  color: $gray_placeholders;
  opacity: 0.5;
}

input:not([disabled]):-ms-input-placeholder,
textarea:not([disabled]):-ms-input-placeholder {
  color: $gray_placeholders;
}

input:not([disabled])::-ms-input-placeholder,
textarea:not([disabled])::-ms-input-placeholder {
  color: $gray_placeholders;
}

/*Change text in autofill textbox*/
input:-webkit-autofill,
textarea:-webkit-autofill {
  @extend %Font-Medium;
  -webkit-text-fill-color: $black;
}

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px $white inset;
}

/** Remove Arrows from input="number" **/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input.mat-input-element {
  margin-top: 0;
}
