@forward "./dialog-theme";
@forward "./input-theme"; // later do it with directive
/* You can add global styles to this file, and also import other style files */

// Plus imports for other components in your app.
@use "./checkbox-theme" as checkbox;
@use "./select-theme" as select;
@use "./radio-theme" as radio;
@use "./button-toggle-theme" as buttonToggle;
@use "./accordion-theme" as accordion;

@include checkbox.configure($checkbox-size: 18px, $border-radius: 4px);
@include checkbox.styles;

@include select.styles;

@include radio.styles;

@include buttonToggle.styles;
@include accordion.styles;
