/* You can add global styles to this file, and also import other style files */
@import "./coreStyles/_var.scss";
@import "./coreStyles/constants.scss";
@import "./coreStyles/themes";

* {
  box-sizing: border-box;
}

.mat-snack-bar-container {
  padding: 16px !important;
  border-radius: 8px !important;
  @extend %Font-Medium;
  font-size: 12px;
  min-width: 205px !important;
}
.success-snackbar {
  border: dashed 1px #639c37;
  background-color: #f6f8f4;
  color: $green;
}

.failure-snackbar {
  border: dashed 1px #bf3a36;
  background-color: #fae9e9;
  color: $warning;
}

.warning-snackbar {
  border: dashed 1px #d09029;
  background-color: #faf4e9;
  color: #d09029;
}

.mat-snack-bar-container.warning-snackbar {
  max-width: none;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: "Quicksand", "Helvetica Neue", sans-serif;
  // font-family: 'Baloo Bhaijaan 2', cursive;
}
