@import "../constants.scss";

$-border-color: $black;
$-background-color: $primary-10;
$-font-color: $primary;

@mixin configure(
  $border-color: null,
  $background-color: null,
  $font-color: null
) {
  @if $border-color {
    $-border-color: $border-color !global;
  }
  @if $background-color {
    $-background-color: $background-color !global;
  }
  @if $font-color {
    $-font-color: $font-color !global;
  }
}

@mixin styles {
  .mat-select-panel {
    &[aria-multiselectable="true"] {
      min-width: calc(100% + 32px) !important;
    }
    &:not(.mat-select-search-panel) {
      margin-top: 30px;
      margin-bottom: 35px;
    }
    .mat-option {
      border-bottom: 1px solid rgba($-border-color, 0.1);
      &.mat-active {
        background-color: $-background-color;
        color: $-font-color;
      }
      &.mat-selected:not(.mat-option-multiple) {
        background: $-background-color;
      }
      &:last-child {
        border-bottom: none;
      }
      .mat-option-text {
        @extend %Font-Medium;
        [class^="ph-"] {
          font-size: 18px;
          vertical-align: sub;
          margin-right: 8px;
        }
      }
    }
    .mat-option-pseudo-checkbox {
      border: 2px solid rgba($black, 0.1);
      &.mat-pseudo-checkbox-checked {
        background-color: $primary;
      }
    }
  }
}
