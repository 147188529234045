@import "../constants.scss";

@mixin styles {
  mat-button-toggle-group {
    &.mat-button-toggle-group-appearance-standard {
      border-radius: 20px;
      border: solid 1px rgba($black, 0.1);
    }
    .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
      @extend %Font-Medium;
      font-size: 14px;
      color: $primary;
      background-color: rgba(var(--primary-color-rgb), 0.1);
    }
    .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
      line-height: 34px;
      padding: 0 16px;
    }
    &.mat-button-toggle-group-appearance-standard
      .mat-button-toggle
      + .mat-button-toggle {
      border-left: 0;
    }
    .mat-button-toggle-appearance-standard {
      color: $black;
      background-color: $white;
    }
  }

  // custom styles
  mat-button-toggle-group.hub-button-toggle-group {
    &.mat-button-toggle-group-appearance-standard {
      border: none;
    }
    .mat-button-toggle-appearance-standard {
      border-radius: 20px;
      &.mat-button-toggle-checked {
        &[color="warn"] {
          background-color: rgba($warning, 0.1);
          color: $warning;
        }
        &[color="accent"] {
          background-color: rgba(#ffff00, 0.1);
          // color: #ffff00;
          color: $black;
        }
        &[color="primary"] {
          background-color: rgba($green, 0.1);
          color: $green;
        }
      }
    }
  }
}
